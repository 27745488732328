import { render, staticRenderFns } from "./template.vue?vue&type=template&id=f40a0ef8&scoped=true&"
import script from "@/Components/Global/CompanyLogo/component.ts?vue&type=script&lang=ts&"
export * from "@/Components/Global/CompanyLogo/component.ts?vue&type=script&lang=ts&"
import style0 from "@/Components/Global/CompanyLogo/style.scss?vue&type=style&index=0&id=f40a0ef8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f40a0ef8",
  null
  
)

export default component.exports