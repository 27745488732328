// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinResponsive from '@/Mixins/MixinResponsive'

// Component Extend
const CompanyLogo = VueMixins(MixinResponsive).extend({
	name: 'CompanyLogo',

	props: {
		home: { type: Boolean, default: false },
		logo: { type: String, default: 'LogoKreisSmall' }
	},

	computed: {
		_getLogo: function(): string {
			return require(`@/Resources/Images/${ this.logo }.png`)
		}
	},

	methods: {
		onLogoClick: function() {
			if (this.home) {
				this.$emit('onLogoClick')
			}
		}
	}
})

// Exports
export default CompanyLogo
export type CompanyLogoRef = InstanceType<typeof CompanyLogo>