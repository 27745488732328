// Components (.vue)
import CompanyLogo         from '@/Components/Global/CompanyLogo/template.vue'
import InitialsButton      from '@/Components/Global/InitialsButton/template.vue'
import NotificationsButton from '@/Components/Global/NotificationsButton/template.vue'

// Constants
import { Documents } from '@/Constants/Documents'
import { VueRouter } from '@/Constants/VueRouter'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const BasicHeader = VueMixins(MixinComponent, MixinResponsive).extend({
	name: 'BasicHeader',

	components: {
		InitialsButton,
		CompanyLogo,
		NotificationsButton
	},

	props: {
		title: { type: String, default: '' }
	},

	data: function() {
		return {
			states: {
				isMobile: false
			}
		}
	},

	computed: {
		_getUserCompanyName: function(): string {
			const user = Store.getters.getStoredUser
			const company = Store.getters.getStoredCompanyById(user._idCompany)
			return company ? company.name : ''
		},

		_getUserFullName: function(): string {
			const user = Store.getters.getStoredUser
			return `${ user.name } ${ user.pLastName }`
		}
	},

	methods: {
		onLogoClick: function() {
			const parentViewName = this.$options.parent.$options.name;
			const currentRoute = this.$route.name;
			switch (Store.getters.getStoredUser._idRole) {
				case Documents.Roles.Kreis.CLIENT:
					if (parentViewName !== VueRouter.Views.Modules.EXTERNAL_CPANEL.NAME && currentRoute !== VueRouter.Views.Modules.EXTERNAL_CPANEL.NAME)
						return this.$router.push({ name: VueRouter.Views.Modules.EXTERNAL_CPANEL.NAME })
						break;
				default:
					if (parentViewName !== VueRouter.Views.Modules.INTERNAL_CPANEL.NAME && currentRoute !== VueRouter.Views.Modules.INTERNAL_CPANEL.NAME)
						return this.$router.push({ name: VueRouter.Views.Modules.INTERNAL_CPANEL.NAME })
						break;
			}
		},

		onLogoutClick: function() {
			Store.dispatch('onDestroyAllData')
			Store.getters.getStoredSocket.emit('userLoggedOut')
			this.$router.push({ name: VueRouter.Views.Global.LOGIN.NAME, params: { successLogout: 'true' } })
		}
	}
})

// Exports
export default BasicHeader
export type BasicHeaderRef = InstanceType<typeof BasicHeader>