// Classes
import { DevelopmentTools } from '@/Classes/Static/DevelopmentTools'
import { PrimitiveTools }   from '@/Classes/Static/PrimitiveTools'

// Constants
import { AppValues } from '@/Constants/AppValues'
import { Server }    from '@/Constants/Server'

// Dependencies
import VueMixins from 'vue-typed-mixins'

// Mixins
import MixinComponent  from '@/Mixins/MixinComponent'
import MixinFetch      from '@/Mixins/MixinFetch'
import MixinResponsive from '@/Mixins/MixinResponsive'

// Store
import Store from '@/Store/Global/Default'

// Component Extend
const NotificationsButton = VueMixins(MixinComponent, MixinFetch, MixinResponsive).extend({
	name: 'NotificationsButton',

	data: function() {
		return {
			states: {
				notifications: [] as Array<any>,
				showNotifications: false
			}
		}
	},

	created: async function() {
		this._fetchNotificationsByIdSystem()
	},

	computed: {
		_getNotifications: function(): Array<any> {
			return (Store.getters.getStoredTempNotifications as Array<any>)
			.filter((x) => !x.closed)
			.sort((a, b) => a.dateCreation < b.dateCreation ? 1 : -1)
		},

		_getNumUnreadNotifications: function(): number {
			return this._getNotifications.filter((x: any) => !x.read).length
		}
	},

	methods: {
		_fetchNotificationsByIdSystem: async function() {
			if (Store.getters.getStoredUpdateAllInProgress) return
			DevelopmentTools.printWarn('[NotificationsButton]:_fetchNotificationsByIdSystem triggered')
			const response = await Store.dispatch('fetchNotificationsByIdSystem', { _idSystem: AppValues.ObjectIds.Modules.SERVICES })
			this.setStates<NotificationsButtonRef['states']>({ notifications: response })
		},

		_getNotificationDate: function(notification: any) {
			const parsedDate = PrimitiveTools.Dates.parseDateString(notification.dateCreation)
			return `${ parsedDate.date } ${ parsedDate.time }`
		},

		_getNotificationItemReadClass: function(notification: any) {
			return notification.read ? 'read' : ''
		},

		onNBMarkAllAsClosed: async function(closed: boolean) {
			DevelopmentTools.printWarn('[NotificationsButton]:onNBMarkAllAsClosed event triggered')
			this.states.notifications.forEach((x) => x.closed = closed)
			Store.commit('storeUpdateAllInProgress', true)
			for (const x of this.states.notifications) await this.onNBMarkNotificationAsClosed(x, closed, false)
			Store.commit('storeUpdateAllInProgress', false)
		},

		onNBMarkAllAsRead: async function(read: boolean) {
			DevelopmentTools.printWarn('[NotificationsButton]:onNBMarkAllAsRead event triggered')
			this.states.notifications.forEach((x) => x.read = read)
			Store.commit('storeUpdateAllInProgress', true)
			for (const x of this.states.notifications) await this.onNBMarkNotificationAsRead(x, read, false)
			Store.commit('storeUpdateAllInProgress', false)
		},

		onNBMarkNotificationAsClosed: async function(notification: any, closed = true, autoFetch = true) {
			DevelopmentTools.printWarn('[NotificationsButton]:onNBMarkNotificationAsClosed event triggered')
			notification.closed = closed

			// Objeto con las Propiedades requeridas por la Petición.
			const body = {
				_idNotification: notification._idNotification,
				closed: notification.closed,
				message: notification.message
			}

			// Realizar la Petición al servidor.
			const response = await this.fetchURL(Server.Fetching.Method.PATCH, Server.Routes.Notifications.UpdateNotificationById, body)

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Respuesta de la Petición.
				const _response = response.data.body
				if (autoFetch) this._fetchNotificationsByIdSystem()
			}
		},

		onNBMarkNotificationAsRead: async function(notification: any, read = true, autoFetch = true) {
			DevelopmentTools.printWarn('[NotificationsButton]:onNBMarkNotificationAsRead event triggered')
			notification.read = read
			
			// Objeto con las Propiedades requeridas por la Petición.
			const body = {
				_idNotification: notification._idNotification,
				read: notification.read
			}

			// Realizar la Petición al servidor.
			const response = await this.fetchURL(Server.Fetching.Method.PATCH, Server.Routes.Notifications.UpdateNotificationReadById, body)

			// Si se obtiene una respuesta satisfactoria, continuar con el proceso.
			if (response.status === Server.Response.StatusCodes.SUCCESS) {
				// Respuesta de la Petición.
				const _response = response.data.body
				if (autoFetch) this._fetchNotificationsByIdSystem()
			}
		},

		onNBToggleWindow: function() {
			DevelopmentTools.printWarn('[NotificationsButton]:onNBToggleWindow event triggered')
			this.setStates<NotificationsButtonRef['states']>({ showNotifications: !this.states.showNotifications })
		}
	},

	watch: {
		'$store.state.G_Notifications.updateAllInProgress': function(newValue: boolean) {
			if (newValue === false) this._fetchNotificationsByIdSystem()
		}
	}
})

// Exports
export default NotificationsButton
export type NotificationsButtonRef = InstanceType<typeof NotificationsButton>