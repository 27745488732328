// Constants
import { Component } from '@/Constants/Component'

// Dependencies
import Vue from 'vue'

// Component Extend
const SubmitButton = Vue.extend({
	name: 'SubmitButton',

	props: {
		action: { type: Number, default: Component.Actions.INSERT },
		isDisabled: Boolean,
		isFetching: Boolean,
		onInsertText: { type: String, default: 'Registrar' },
		onReadText: { type: String, default: 'Leer' },
		onUpdateText: { type: String, default: 'Actualizar' },
		variant: { type: String, default: 'green' }
	},

	methods: {
		_getText: function() {
			const { action, onInsertText, onUpdateText } = this.$props
			return action === Component.Actions.INSERT ? onInsertText : onUpdateText
		}
	}
})

// Exports
export default SubmitButton
export type SubmitButtonRef = InstanceType<typeof SubmitButton>